'use client';

import {
  Box,
  Button,
  Content,
  ContentWrapper,
  ImageStyle,
  ImageWrapper,
  List,
  ListItem,
  RightPanelContainer,
  TabContent,
  TabText,
  TabTextBold,
  TabTitle,
  WorkingWithUsSectionContainer,
  WorkingWithUsSectionIntro,
} from './working-with-us-section.style';
import { getOffsetTopRelativeToBody } from '@exploration/pages-shared';
import { useBreakpoint, useScrollY } from '@exploration/ui-utils';
import { explorationFeatureFlagAtom } from '@thrivent-web/exploration/feature-management';
import { useAtomValue } from 'jotai';
import { clamp } from 'ramda';
import { SyntheticEvent, useRef, useState } from 'react';

type Props = {
  title: string;
  description: string;
  listItems: {
    key: string;
    title: string;
    description: string;
    description2?: string;
  }[];
  images: {
    key: string;
    url: string;
    alt: string;
    width: string;
    height: string;
  }[];
};

export const WorkingWithUsSection = ({
  title,
  description,
  listItems,
  images,
}: Props) => {
  const flags = useAtomValue(explorationFeatureFlagAtom);
  const isScrollEventsEnabled = flags['enable-working-with-us-scroll-events'];
  const [currentHeadingIndex, setCurrentHeadingIndex] = useState<number>(0);
  const scrollTriggerOverride = useRef<boolean>(false);

  function goToTab(e: SyntheticEvent, newIndex: number, title: string) {
    scrollTriggerOverride.current = true;
    setCurrentHeadingIndex(newIndex);
    history.replaceState(undefined, '', `#${title}`);
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const box0 = useRef<HTMLDivElement>(null);
  const box1 = useRef<HTMLDivElement>(null);
  const box2 = useRef<HTMLDivElement>(null);
  const allBoxes = [box0, box1, box2];

  if (isScrollEventsEnabled) {
    // blur the button of the tabs that are not selected
    // this is to prevent the focus from scrolling the page to the focused tab
    allBoxes.forEach((box, index) => {
      if (box.current) {
        const headingButton = box.current.querySelector('button');
        if (headingButton) {
          if (currentHeadingIndex !== index) {
            headingButton.blur();
          }
        }
      }
    });
  }

  const bp = useBreakpoint();
  const bpMobileOrTablet = bp === 'mobile' || bp === 'tablet';

  useScrollY((scrollY: number) => {
    if (!isScrollEventsEnabled) {
      return;
    }
    // When the user resumes scrolling, we need to reset the manual override
    if (scrollTriggerOverride.current) {
      scrollTriggerOverride.current = false;
      return;
    }

    //Disable the scroll trigger on mobile and tablet
    if (containerRef.current && contentRef.current && !bpMobileOrTablet) {
      const container = containerRef.current;
      const containerOffsetTop = getOffsetTopRelativeToBody(container);
      const contentFullHeight = contentRef.current.clientHeight;
      const scrolledIntoContainer = scrollY - containerOffsetTop;
      // The modifier is used to adjust the sensitivity of the scroll trigger
      const modifier = 5;

      const currentIndex = clamp(
        0,
        2,
        Math.floor(scrolledIntoContainer / (contentFullHeight / modifier)) + 1,
      );

      setCurrentHeadingIndex(currentIndex);
    }
  });

  return (
    <WorkingWithUsSectionContainer ref={containerRef}>
      <WorkingWithUsSectionIntro title={title} description={description} />

      <Content ref={contentRef}>
        <List role="tablist" aria-label="Working with us Tabs">
          {listItems.map((listItem, index) => (
            <ListItem
              key={listItem.title}
              ref={allBoxes[index]}
              role="tab"
              className={currentHeadingIndex === index ? 'active' : 'inactive'}
              aria-label={listItem.title}
              aria-selected={currentHeadingIndex === index}
              aria-expanded={currentHeadingIndex === index}
              aria-controls={`${listItem.key}-content`}
            >
              <Button
                id={listItem.key}
                onClick={(e: SyntheticEvent) => goToTab(e, index, listItem.key)}
              >
                <TabTitle>{listItem.title}</TabTitle>
              </Button>
              <ContentWrapper
                className="animate__animated"
                data-state={
                  currentHeadingIndex === index ? 'active' : 'inactive'
                }
                role="tabpanel"
                aria-labelledby={listItem.key}
                id={`${listItem.key}-content`}
              >
                <TabContent className="animate__animated">
                  <TabText>{listItem.description}</TabText>
                  {listItem.description2 ? (
                    <TabTextBold as="p">{listItem.description2}</TabTextBold>
                  ) : null}
                </TabContent>
              </ContentWrapper>
            </ListItem>
          ))}
        </List>
        <RightPanelContainer>
          {images.map((image, index) => (
            <Box
              key={image.key}
              className={`box ${currentHeadingIndex === index ? 'active' : ''}`}
              id={image.key}
            >
              <ImageWrapper className="animate__animated">
                <ImageStyle
                  src={image.url}
                  alt={image.alt}
                  width={+image.width}
                  height={+image.height}
                />
              </ImageWrapper>
            </Box>
          ))}
        </RightPanelContainer>
      </Content>
    </WorkingWithUsSectionContainer>
  );
};
